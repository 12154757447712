export default {
  themes: {
    default: {
      light: {
        colors: {

          customBtnBgColor: '#adadad',

          customNavColor: '#64748B',
          customContainerBorder: '#d6d6d6',
          customContainerBg: '#ffffff',

          activeStatus: '#22C55E',
          activeStatusBg: '#c9ffde',
          activeStatusBorder: '#22C55E',

          deactiveStatus: '#DC2626',
          deactiveStatusBg: '#fac3c3',
          deactiveStatusBorder: '#EF4444',

          textbase: '#1f1f1f',
          surface: '#fff',
          primary: '#009fb7',
          secondary: '#6a6a6f',
          accent: '#ffd867',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#fecf65',
          timeline: '#cbcbcb',
          timelineBg: '#ffffff',
          customBg: '#e9edef',
          customDrawerBg: '#e9edef',
          tableBorderLinesColor: '#6a6a6f',
          customInputBorderColor: '#6a6a6f',
          customStepLogSelect: '#f0eff4',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      },
      dark: {
        colors: {

          customBtnBgColor: '#6a6a6f',
          customNavColor: '#FFFFFF',

          searchandpermBg: '#1C1C1C',
          customContainerBorder: '#6a6a6f',
          customContainerBg: '#101114',

          activeStatus: '#51A570',
          activeStatusBg: '#0D331C',
          activeStatusBorder: '#51A570',

          deactiveStatus: '#F05555',
          deactiveStatusBg: '#3D1212',
          deactiveStatusBorder: '#F05555',

          textbase: '#ecefef',
          surface: '#101114',
          primary: '#009fb7',
          secondary: '#6a6a6f',
          accent: '#ffd867',
          error: '#FF5252',
          info: '#2196F3',
          success: '#05c075',
          warning: '#febf08',
          timeline: '#505050',
          timelineBg: '#0f0f0f',
          customBg: '#23272e',
          customDrawerBg: '#1B1C1C',
          tableBorderLinesColor: '#6a6a6f',
          customInputBorderColor: '#858585',
          customStepLogSelect: '#2a2b2a',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      }

    },
    dark: {
      colors: {

        customBtnBgColor: '#6a6a6f',
        customNavColor: '#FFFFFF',

        searchandpermBg: '#1C1C1C',
        customContainerBorder: '#6a6a6f',
        customContainerBg: '#101114',

        activeStatus: '#51A570',
        activeStatusBg: '#0D331C',
        activeStatusBorder: '#51A570',

        deactiveStatus: '#F05555',
        deactiveStatusBg: '#3D1212',
        deactiveStatusBorder: '#F05555',

        textbase: '#ecefef',
        surface: '#101114',
        primary: '#009fb7',
        secondary: '#6a6a6f',
        accent: '#ffd867',
        error: '#FF5252',
        info: '#2196F3',
        success: '#05c075',
        warning: '#febf08',
        timeline: '#505050',
        timelineBg: '#0f0f0f',
        customBg: '#23272e',
        customDrawerBg: '#1B1C1C',
        tableBorderLinesColor: '#6a6a6f',
        customInputBorderColor: '#6a6a6f',
        customStepLogSelect: '#2a2b2a',
        processDiagramFlowNodeBackground: '#FFE082',
        processDiagramSimpleNodeBackground: '#90CAF9'
      }
    },
    light: {
      colors: {

        customBtnBgColor: '#adadad',

        customNavColor: '#64748B',
        customContainerBorder: '#d6d6d6',
        customContainerBg: '#ffffff',

        activeStatus: '#22C55E',
        activeStatusBg: '#c9ffde',
        activeStatusBorder: '#22C55E',

        deactiveStatus: '#DC2626',
        deactiveStatusBg: '#fac3c3',
        deactiveStatusBorder: '#EF4444',

        textbase: '#1f1f1f',
        surface: '#f0eff4',
        primary: '#009fb7',
        secondary: '#6a6a6f',
        accent: '#ffd867',
        error: '#ef476f',
        info: '#2196F3',
        success: '#05c075',
        warning: '#fecf65',
        timeline: '#cbcbcb',
        timelineBg: '#ffffff',
        customBg: '#e9edef',
        customDrawerBg: '#e9edef',
        tableBorderLinesColor: '#6a6a6f',
        customInputBorderColor: '#858585',
        customStepLogSelect: '#f0eff4',
        processDiagramFlowNodeBackground: '#FFE082',
        processDiagramSimpleNodeBackground: '#90CAF9'
      }
    },
    goldenrod: {
      dark: {
        colors: {
          customContainerBg: '#101114',
          customContainerBorder: '#6a6a6f',
          customNavColor: '#FFFFFF',

          activeStatus: '#51A570',
          activeStatusBg: '#0D331C',
          activeStatusBorder: '#51A570',

          deactiveStatus: '#F05555',
          deactiveStatusBg: '#3D1212',
          deactiveStatusBorder: '#F05555',

          textbase: '#ecefef',
          surface: '#101114',
          primary: '#c78b19',
          secondary: '#3a3f55',
          accent: '#897255',
          error: '#FF5252',
          info: '#2196F3',
          success: '#05c075',
          warning: '#febf08',
          timeline: '#505050',
          timelineBg: '#0f0f0f',
          customBg: '#1a262f',
          customDrawerBg: '#1B1C1C',
          tableBorderLinesColor: '#3a3f55',
          customInputBorderColor: '#858585',
          customStepLogSelect: '#2a2b2a',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      },

      // light theme colors
      light: {
        colors: {

          customContainerBg: '#ffffff',
          customContainerBorder: '#d6d6d6',
          customNavColor: '#64748B',

          activeStatus: '#22C55E',
          activeStatusBg: '#c9ffde',
          activeStatusBorder: '#22C55E',

          deactiveStatus: '#DC2626',
          deactiveStatusBg: '#fac3c3',
          deactiveStatusBorder: '#EF4444',

          textbase: '#1f1f1f',
          surface: '#f0eff4',
          primary: '#af7608',
          secondary: '#393337',
          accent: '#897255',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#fecf65',
          timeline: '#cbcbcb',
          timelineBg: '#ffffff',
          customBg: '#f1f5fa',
          customDrawerBg: '#f1f5fa',
          tableBorderLinesColor: '#595959',
          customInputBorderColor: '#858585',
          customStepLogSelect: '#f0eff4',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      }
    },
    magenta: {
      dark: {
        colors: {

          customContainerBg: '#101114',
          customContainerBorder: '#6a6a6f',
          customNavColor: '#FFFFFF',

          activeStatus: '#51A570',
          activeStatusBg: '#0D331C',
          activeStatusBorder: '#51A570',

          deactiveStatus: '#F05555',
          deactiveStatusBg: '#3D1212',
          deactiveStatusBorder: '#F05555',

          textbase: '#ecefef',
          surface: '#101114',
          primary: '#A3006A',
          secondary: '#a39ba8',
          accent: '#4D7298',
          error: '#FF5252',
          info: '#2196F3',
          success: '#05c075',
          warning: '#febf08',
          timeline: '#505050',
          timelineBg: '#0f0f0f',
          customBg: '#14000E',
          customDrawerBg: '#1B1C1C',
          tableBorderLinesColor: '#a39ba8',
          customInputBorderColor: '#858585',
          customStepLogSelect: '#2a2b2a',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      },

      // light theme colors
      light: {
        colors: {

          customContainerBg: '#ffffff',
          customContainerBorder: '#d6d6d6',
          customNavColor: '#64748B',

          activeStatus: '#22C55E',
          activeStatusBg: '#c9ffde',
          activeStatusBorder: '#22C55E',

          deactiveStatus: '#DC2626',
          deactiveStatusBg: '#fac3c3',
          deactiveStatusBorder: '#EF4444',

          textbase: '#1f1f1f',
          surface: '#f0eff4',
          primary: '#672A4E',
          secondary: '#505050',
          accent: '#5EDDFD',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#fecf65',
          timeline: '#cbcbcb',
          timelineBg: '#ffffff',
          customBg: '#FFD6F3',
          customDrawerBg: '#FFEBF9',
          tableBorderLinesColor: '#820B8A',
          customInputBorderColor: '#858585',
          customStepLogSelect: '#f0eff4',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      }
    },
    violet: {
      dark: {
        colors: {

          customContainerBg: '#101114',
          customContainerBorder: '#6a6a6f',
          customNavColor: '#FFFFFF',

          activeStatus: '#51A570',
          activeStatusBg: '#0D331C',
          activeStatusBorder: '#51A570',

          deactiveStatus: '#F05555',
          deactiveStatusBg: '#3D1212',
          deactiveStatusBorder: '#F05555',

          textbase: '#ecefef',
          surface: '#101114',
          primary: '#A356F0',
          secondary: '#585265',
          accent: '#fec85a',
          error: '#FF5252',
          info: '#2196F3',
          success: '#05c075',
          warning: '#febf08',
          timeline: '#505050',
          timelineBg: '#0f0f0f',
          customBg: '#271F30',
          customDrawerBg: '#1B1C1C',
          tableBorderLinesColor: '#594771',
          customInputBorderColor: '#858585',
          customStepLogSelect: '#2a2b2a',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      },

      // light theme colors
      light: {
        colors: {

          customContainerBg: '#ffffff',
          customContainerBorder: '#d6d6d6',
          customNavColor: '#64748B',

          activeStatus: '#22C55E',
          activeStatusBg: '#c9ffde',
          activeStatusBorder: '#22C55E',

          deactiveStatus: '#DC2626',
          deactiveStatusBg: '#fac3c3',
          deactiveStatusBorder: '#EF4444',

          textbase: '#1f1f1f',
          surface: '#f0eff4',
          primary: '#7A14E1',
          secondary: '#343434',
          accent: '#af7608',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#af7608',
          timeline: '#cbcbcb',
          timelineBg: '#ffffff',
          customBg: '#e7d9e7',
          customDrawerBg: '#d8cedf',
          tableBorderLinesColor: '#656565',
          customInputBorderColor: '#858585',
          customStepLogSelect: '#f0eff4',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      }
    },
    greenCrayola: {
      dark: {
        colors: {

          customContainerBg: '#101114',
          customContainerBorder: '#6a6a6f',
          customNavColor: '#FFFFFF',

          activeStatus: '#51A570',
          activeStatusBg: '#0D331C',
          activeStatusBorder: '#51A570',

          deactiveStatus: '#F05555',
          deactiveStatusBg: '#3D1212',
          deactiveStatusBorder: '#F05555',

          textbase: '#ecefef',
          surface: '#101114',
          primary: '#7E9933',
          secondary: '#345995',
          accent: '#CBEAA6',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#febf08',
          timeline: '#505050',
          timelineBg: '#0f0f0f',
          customBg: '#2a2d25',
          customDrawerBg: '#1B1C1C',
          tableBorderLinesColor: '#345995',
          customInputBorderColor: '#858585',
          customStepLogSelect: '#2a2b2a',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      },

      // light theme colors
      light: {
        colors: {

          customContainerBg: '#ffffff',
          customContainerBorder: '#d6d6d6',
          customNavColor: '#64748B',

          activeStatus: '#22C55E',
          activeStatusBg: '#c9ffde',
          activeStatusBorder: '#22C55E',

          deactiveStatus: '#DC2626',
          deactiveStatusBg: '#fac3c3',
          deactiveStatusBorder: '#EF4444',

          textbase: '#1f1f1f',
          surface: '#f0eff4',
          primary: '#7E9933',
          secondary: '#345995',
          accent: '#03440C',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#fecf65',
          timeline: '#cbcbcb',
          timelineBg: '#ffffff',
          customBg: '#fbfeee',
          customDrawerBg: '#CBEAA6',
          tableBorderLinesColor: '#345995',
          customInputBorderColor: '#858585',
          customStepLogSelect: '#2a2b2a',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      }
    },
    blue: {
      dark: {
        colors: {

          customContainerBg: '#101114',
          customContainerBorder: '#6a6a6f',
          customNavColor: '#FFFFFF',

          activeStatus: '#51A570',
          activeStatusBg: '#0D331C',
          activeStatusBorder: '#51A570',

          deactiveStatus: '#F05555',
          deactiveStatusBg: '#3D1212',
          deactiveStatusBorder: '#F05555',

          textbase: '#ecefef',
          surface: '#101114',
          primary: '#227fea',
          secondary: '#505050',
          accent: '#ffd867',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#febf08',
          timeline: '#505050',
          timelineBg: '#0f0f0f',
          customBg: '#012d42',
          customDrawerBg: '#0a0a0d',
          tableBorderLinesColor: '#518cc6',
          customInputBorderColor: '#858585',
          customStepLogSelect: '#ebebfe',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      },

      // light theme colors
      light: {
        colors: {

          customContainerBg: '#ffffff',
          customContainerBorder: '#d6d6d6',
          customNavColor: '#64748B',

          activeStatus: '#22C55E',
          activeStatusBg: '#c9ffde',
          activeStatusBorder: '#22C55E',

          deactiveStatus: '#DC2626',
          deactiveStatusBg: '#fac3c3',
          deactiveStatusBorder: '#EF4444',

          textbase: '#1f1f1f',
          surface: '#f0eff4',
          primary: '#0065cd',
          secondary: '#000',
          accent: '#ffd867',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#fecf65',
          timeline: '#cbcbcb',
          timelineBg: '#ffffff',
          customBg: '#bfecf2',
          customDrawerBg: '#e9edef',
          tableBorderLinesColor: '#606060',
          customInputBorderColor: '#858585',
          customStepLogSelect: '#f0eff4',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      }
    }
  }
}
