<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <div v-click-outside="hideSearch" :class="{ 'overall-search': true, 'overall-search-should-closed': shouldHideOnSmallScreens }">
    <v-text-field
      v-if="!searchField.length"
      v-model.trim="searchValue"
      variant="outlined"
      density="compact"
      clearable
      clear-icon="mdi-close"
      hide-details
      :placeholder="$lang.labels.search"
      prepend-inner-icon="mdi-magnify"
      class="expanding-search"
      data-cy="global-search"
      :loading="loading"
      :class="{ closed: searchBoxClosed && !searchValue }"
      @keyup.enter="search"
      @focus="searchBoxClosed = false; show = !!result.length"
      @blur="searchBoxClosed = true"
      @click:clear="result = []; show = false"
    />
    <v-text-field
      v-else
      v-model.trim="searchValue"
      velse
      variant="outlined"
      density="comfortable"
      clearable
      clear-icon="close"
      hide-details
      :placeholder="$lang.labels.addTemplate"
      prepend-inner-icon="mdi-magnify"
      class="expanding-search"
      data-cy="global-search"
      :loading="loading"
      @keyup.enter="searchOneField"
      @focus="show = !!result.length"
      @click:clear="result = []; show = false"
    />
    <v-expand-transition v-if="show && !searchField">
      <v-list class="search-result">
        <v-list-item
          v-for="(item, i) in result"
          :key="i"
          class="result-item"
          :to="'/' + $route.params.lang + item.route + item.id"
          link
          @click="show = false"
        >
          <v-list-item-title class="d-flex align-center">
            <v-icon size="x-small">
              {{ item.icon }}
            </v-icon>
            <div class="ml-1">
              {{ item.name }}
            </div>
          </v-list-item-title>
          <v-list-item-subtitle v-text="item.comment" />
        </v-list-item>
        <v-list-item v-if="(searchValue && searchValue.length >= 3 && !result.length) || !timer">
          <div class="no-search-result">
            No search result
          </div>
        </v-list-item>
      </v-list>
    </v-expand-transition>
    <v-expand-transition v-if="show && searchField">
      <v-list class="search-result">
        <v-list-item

          v-for="(item, i) in result"
          :key="i"
          class="result-item"
          @click="add(item.name); searchValue = null"
        >
          <v-list-item-title class="d-flex align-center">
            <v-icon size="small">
              {{ item.icon }}
            </v-icon>
            <div class="ml-1">
              {{ item.name }}
            </div>
          </v-list-item-title>
          <v-list-item-subtitle v-text="item.comment" />
        </v-list-item>
        <v-list-item v-if="isSearchValueLongEnoughAndNoResult || !isTimerActive">
          <div class="no-search-result">
            No search result
          </div>
        </v-list-item>
      </v-list>
    </v-expand-transition>
  </div>
</template>

<script>
import {
  getTriggerCronsUsingGET as getCrons,
  getTriggerMessagingsUsingGET as getMessagings,
  getTriggerRestsUsingGET as getRests,
  getProcessesUsingGET as getProcesses,
  getProcessCredentialsUsingGET as getCredentials,
  getTextTemplatesUsingGET as getTemplates,
  getTranslationsUsingGET as getTranslations,
  getValidationRulesUsingGET as getValidations,
  getPluginsUsingGET as getPlugins,
  getModulesUsingGET as getModules,
  getUsersUsingGET as getUsers,
  getRolesUsingGET as getRoles,
  getLibrariesUsingGET as getLibraries,
  getStoragesUsingGET as getStorage, getProcessByIdUsingGET as getProcess,
  getTriggerEventHandlersUsingGET as getEventHandlers,
  getEntitiesUsingGET as getEntities,
  getStaticResourcesUsingGET as getStaticResources
} from '@/utils/api'

export default {
  props: {
    searchField: {
      type: String,
      default: () => ''
    },
    shouldHideOnSmallScreens: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      show: false,
      searchValue: '',
      searchBoxClosed: true,
      loading: false,
      timer: null,
      result: []
    }
  },
  computed: {
    isSearchValueLongEnoughAndNoResult() {
      return this.searchValue && this.searchValue.length >= 3 && !this.result.length
    },
    isTimerActive() {
      return this.timer !== null
    }
  },
  watch: {
    searchValue: {
      handler() {
        if (!this.searchValue) {
          this.show = false
          this.result = []
        }
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          if (this.searchField) {
            this.searchOneField()
          } else {
            this.search()
          }
        }, 750)
      }
    }
  },
  methods: {
    add(name) {
      this.$emit('addTemplate', name )
      this.show = false
    },
    searchOneField() {
      this.result = []
      this.show = false
      this.loading = false
      if (!this.searchValue || this.searchValue.length < 3) {
        return
      }
      this.loading = true

      const arr = []

      if (this.searchField) {
        switch (this.searchField) {
        case 'getTemplates':

          // eslint-disable-next-line no-case-declarations
          const field = getTemplates({ name: this.searchValue })

          arr.push(field)

          break
        }

      }
      Promise.all(arr).then((data) => {
        data.map((item) => {
          // eslint-disable-next-line consistent-return,array-callback-return
          const result = item.data.data.items.filter((i) => {

            if (+i.id !== +this.$route.params.id) {
              const options = this.getModuleIcon(item.config.url)

              i.icon = options.icon
              i.route = options.url

              return i
            }
          })

          this.result.push(...result)

          return result
        })
      }).catch((error) => {
        console.log('error', error)
      }).finally(() => {
        this.show = true
        this.loading = false
      })
    },
    search() {
      this.result = []
      this.show = false
      this.loading = false
      if (!this.searchValue || this.searchValue.length < 3) {
        return
      }
      this.loading = true
      const crons =  getCrons({ name: this.searchValue })
      const messagings = getMessagings({ name: this.searchValue })
      const rests = getRests({ name: this.searchValue })
      const processes = getProcesses({ name: this.searchValue })
      const credentials = getCredentials({ name: this.searchValue })
      const templates = getTemplates({ name: this.searchValue })
      const translations = getTranslations({ name: this.searchValue })
      const validations = getValidations({ name: this.searchValue })
      const plugins = getPlugins({ name: this.searchValue })
      const modules = getModules({ name: this.searchValue })
      const users = getUsers({ name: this.searchValue })
      const roles = getRoles({ name: this.searchValue })
      const libraries = getLibraries({ name: this.searchValue })
      const storageKeys = getStorage({ key: this.searchValue })
      const eventHandlers = getEventHandlers({ name: this.searchValue })
      const entities = getEntities({ name: this.searchValue })
      const statiicResources = getStaticResources({ name: this.searchValue })

      Promise.all([
        crons,
        messagings,
        rests,
        processes,
        credentials,
        templates,
        translations,
        validations,
        plugins,
        modules,
        users,
        roles,
        libraries,
        storageKeys,
        eventHandlers,
        entities,
        statiicResources
      ]).then((data) => {
        data.map(async (item) => {
          const resultMap = item.data.data.items.map(async (i) => {
            const options = await this.getModuleIcon(item.config.url, i)

            i.icon = options.icon
            i.route = options.url

            if (options.processName) {
              i.name = i.key + ' (' + options.processName + ')'
            }

            return i
          })

          await Promise.all(resultMap)
            .then((result) => {
              this.result.push(...result)

              return result
            })
            .catch((error) => {
              console.log(error)
            })
        })
      }).catch((error) => {
        console.log('error', error)
      }).finally(() => {
        this.show = true
        this.loading = false
      })
    },
    async getModuleIcon(type, item) {
      if (type.includes('trigger-cron')) {
        return {
          icon: 'mdi-briefcase-clock-outline',
          url: '/triggers/cron/edit/'
        }
      } else if (type.includes('trigger-messaging')) {
        return {
          icon: 'mdi-message-text-outline',
          url: '/triggers/messaging/edit/'
        }
      } else if (type.includes('trigger-rest')) {
        return {
          icon: 'mdi-web',
          url: '/triggers/rest/edit/'
        }
      } else if (type.includes('process-credential')) {
        return {
          icon: 'mdi-lock',
          url: '/credentials/edit/'
        }
      } else if (type.includes('process')) {
        return {
          icon: 'mdi-console-network',
          url: '/processes/edit/'
        }
      } else if (type.includes('text-template')) {
        return {
          icon: 'mdi-text-box-outline',
          url: '/templates/templates/edit/'
        }
      } else if (type.includes('translation')) {
        return {
          icon: 'mdi-text',
          url: '/templates/translations/edit/'
        }
      } else if (type.includes('validation-rule')) {
        return {
          icon: 'mdi-magnify-scan',
          url: '/validations/edit/'
        }
      } else if (type.includes('plugin')) {
        return {
          icon: 'mdi-puzzle-outline',
          url: '/plugins/edit/'
        }
      } else if (type.includes('module')) {
        return {
          icon: 'mdi-view-module-outline',
          url: '/modules/local/edit/'
        }
      } else if (type.includes('user')) {
        return {
          icon: 'mdi-account',
          url: '/permissions/users/edit/'
        }
      } else if (type.includes('role')) {
        return {
          icon: 'mdi-account-group-outline',
          url: '/permissions/roles/edit/'
        }
      } else if (type.includes('library')) {
        return {
          icon: 'mdi-library-outline',
          url: '/libraries/edit/'
        }
      } else if (type.includes('storage')) {
        const process = await this.fetchProcess(item.processId)

        return {
          icon: 'mdi-cube',
          url: '/storage/edit/',
          processName: process.name
        }
      } else if (type.includes('trigger-event-handler')) {
        return {
          icon: 'mdi-briefcase-outline',
          url: '/triggers/event-handler/edit/'
        }
      } else if (type.includes('entity')) {
        return {
          icon: 'mdi-database-settings-outline',
          url: '/entities/edit/'
        }
      } else if (type.includes('static-resource')) {
        return {
          icon: 'mdi-image',
          url: '/static-resources/edit/'
        }
      } else {
        return ''
      }
    },
    hideSearch() {
      this.show = false
      this.searchBoxClosed = true
    },
    async fetchProcess(id) {
      return new Promise((resolve, reject) => {
        getProcess({ id })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(() => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .overall-search {
    display: flex;
    width: 100%;

    .result-item {
      cursor: pointer;
    }

    .search-result {
      max-height: 350px;
      position: absolute;
      overflow-x: hidden;
      overflow-y: auto;
      border: 2px solid rgb(var(--v-theme-primary));
      border-radius: 5px;
      z-index: 1;
      right: 200px;
      top: 50px;
    }

    .no-search-result {
      display: flex;
      justify-content: center;
    }

    .expanding-search {
      transition: max-width 0.5s;
      margin-left: auto;
      margin-right: 0 !important;
      width: 300px;
      max-width: 300px;

      &.closed {
        max-width: 48px;
      }

      ::v-deep(.v-field--active .v-icon) {
        color: rgb(var(--v-theme-primary)) !important;
        caret-color: rgb(var(--v-theme-primary)) !important;
      }
    }
  }

  @media screen and (max-width: 1060px) {
    .overall-search {
      width: 300px;

      .search-result {
        width: 300px;
        overflow-x: auto;
      }
    }
  }

  @media screen and (max-width: 890px) {
    .overall-search-should-closed {
      display: none !important;
    }
  }
</style>
