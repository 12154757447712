module.exports = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  JWT: 'JWT',
  MONGODB: 'Mongo DB',
  JDBC: 'JDBC',
  SMTP: 'SMTP',
  TWILIO: 'Twilio',
  OAUTH: 'OAuth',
  OAUTH2_REFRESH_TOKEN: 'OAuth',
  SLACK: 'Slack',
  DELETE: 'Delete',
  GET: 'Get',
  PATCH: 'Patch',
  POST: 'Post',
  PUT: 'Put',
  SUCCESSFUL: 'Successful',
  UNSUCCESSFUL: 'Unsuccessful',
  FAILED: 'Failed',
  IN_PROGRESS: 'In progress',
  NEW: 'New',
  ON_HOLD: 'On hold',
  CRON: 'Cron',
  REST: 'Rest',
  RUN_PROCESS: 'Run process',
  RERUN_EVENT: 'Rerun event',
  VALIDATION_FAILED: 'Validation Failed',
  EMAIL: 'Smtp',
  EXECUTE_PROCESS: 'Execute process',
  FOREACH: 'For Each',
  GROOVY: 'Groovy',
  JS: 'JavaScript',
  KOTLIN: 'Kotlin',
  SWITCH: 'Switch',
  WHILE: 'While',
  DO_WHILE: 'Do While',
  EDIT: 'Edit',
  VIEW: 'View',
  USE: 'Use',
  PROCESS_INACTIVE: 'Process Inactive',
  FRONTEND: 'Frontend',
  INSTANCE: 'Instance',
  PASTE: 'Paste from memory',
  CREATE: 'Create',
  YES: 'Yes',
  NO: 'No',
  PICTURE: 'Picture',
  FONT: 'Font',
  GLOBAL_TRANSLATION: 'Translation',
  MODULE: 'Module',
  PROCESS: 'Process',
  PROCESS_CREDENTIAL: 'Credential',
  SETTING: 'Setting',
  TEXT_TEMPLATE: 'Text Template',
  TEMPLATE: 'Template',
  TRIGGER_CRON: 'Trigger Cron',
  TRIGGER_REST: 'Trigger Rest',
  STATIC_RESOURCE: 'Static Resource',
  NUMBER: 'Number',
  STRING: 'String',
  MAX_LENGTH: 'Max Length',
  MAX_VALUE: 'Max Value',
  MIN_LENGTH: 'Min Length',
  MIN_VALUE: 'Min Value',
  BODY: 'Body',
  HEADERS: 'Headers',
  QUERY_PARAMS: 'Query Params',
  VALIDATION_RULE: 'Validation Rule',
  FLOWY: 'Flowy',
  BOOLEAN: 'Boolean',
  TIMESTAMP: 'Timestamp',
  INTEGER: 'Integer',
  FLOAT: 'Float',
  PROCESS_SETTING: 'Process Settings',
  CHECK_EMAIL_AVAILABILITY: 'Check Email Availability',
  CREATE_USER: 'Create User',
  GENERATE_API_KEY: 'Generate API Key',
  VALIDATE_API_KEY: 'Validate API Key',
  USER: 'User',
  FILE_JSON: 'File JSON',
  FILE_XML: 'File XML',
  PLUGIN: 'Plugin',
  AWS: 'AWS',
  S3: 'S3',
  LIST: 'List',
  READ: 'Read',
  UNSET_VARIABLES: 'Unset Variables',
  SECURITY: 'Security',
  DECRYPT: 'Decrypt',
  ENCRYPT: 'Encrypt',
  HANDLEBARS: 'Handlebars',
  MUSTACHE: 'Mustache',
  THYMELEAF: 'Thymeleaf',
  VELOCITY: 'Velocity',
  SCRIPT: 'Script',
  IMAP: 'Imap',
  TIMEOUT: 'Timeout',
  MARK_READ: 'Mark Read',
  MARK_UNREAD: 'Mark Unread',
  SEARCH: 'Search',
  CUSTOM: 'Custom',
  PATH_VARIABLES: 'Path variables',
  PDF: 'PDF',
  FINISHED: 'Finished',
  CLEAN_UP: 'Clean Up',
  FIX_EXECUTION_STATE: 'Fix Execution State',
  MAX_SIZE: 'Max Size in bytes',
  PROCESS_EVENTS: 'Process events',
  ARRAY: 'Array',
  DOUBLE: 'Double',
  MIN_SIZE: 'Min size in bytes',
  MAX_SIZE_ARRAY: 'Max. amount of records',
  MIN_SIZE_ARRAY: 'Min. amount of records',
  VALIDATE_PASSWORD: 'Validate password',
  GET_USER_DETAILS: 'Get user details',
  GET_USER_ROLES: 'Get user roles',
  CREDENTIALS_SYNC: 'Credentials sync',
  CONNECTIONS_SYNC: 'Connections sync',
  CRON_TRIGGERS_SYNC: 'Cron triggers sync',
  INSTANCE_HEARTBEAT: 'Instance heartbeat',
  PLUGINS_SYNC: 'Plugins sync',
  REST_TRIGGER_SYNC: 'Rest trigger sync',
  SETTINGS_SYNC: 'Settings sync',
  VALIDATION: 'Validation Rule',
  OBJECT: 'Object',
  LONG: 'Long',
  ATTACHMENTS: 'Get attachments',
  CSV: 'CSV file',
  CSV_TO_JSON: 'CSV to JSON',
  JSON_TO_CSV: 'JSON to CSV',
  CONVERT: 'Convert',
  DOWNLOAD: 'Download',
  INFO: 'Info',
  RESCALE: 'Rescale',
  IMAGE: 'Image',
  FROM_TEMPLATE: 'From template',
  EXECUTE_EXTERNAL_COMMAND: 'Execute external command',
  TRY_CATCH: 'Try Catch',
  KAFKA: 'Kafka',
  MESSAGING: 'Messaging',
  TRIGGER_MESSAGING: 'Trigger Messaging',
  INSTANCE_ADMIN: 'Instance Admin',
  INSTANCE_PROCESSING: 'Instance Processing',
  PROCESSING: 'Processing',
  ADMIN: 'Admin',
  PAYMENT_SENSE: 'Payment Sense',
  PAYMENT_SENSE_PAC: 'Paymentsense PAC',
  CANCEL_TRANSACTION: 'Cancel Transaction',
  GET_REPORT: 'Get Report',
  GET_TERMINAL: 'Get Terminal',
  GET_TERMINALS: 'Get Terminals',
  GET_TRANSACTION: 'Get Transaction',
  SIGNATURE: 'Signature',
  START_REPORT: 'Start Report',
  START_TRANSACTION: 'Start Transaction',
  BANKING: 'Banking',
  END_OF_DAY: 'End of Day',
  X_BALANCE: 'X Balance',
  Z_BALANCE: 'Z Balance',
  ACCOUNT_VERIFICATION: 'Account Verification',
  COMPLETION: 'Completion',
  DUPLICATE: 'Duplicate',
  PRE_AUTH: 'Pre Auth',
  REFUND: 'Refund',
  SALE: 'Sale',
  JMS: 'JMS',
  CLEAN_CAPTCHA: 'Clean captcha',
  MESSAGING_TRIGGER_SYNC: 'Messaging trigger sync',
  PAYMENT_SENSE_CONNECT_E: 'Paymentsense Connect E',
  ACCESS_TOKEN: 'Access Token',
  CROSS_REFERENCE_PAYMENT: 'Cross Reference Payment',
  PAYMENT_DETAILS: 'Payment Details',
  PAYMENT_METHODS: 'Payment Methods',
  RESUME_PAYMENT: 'Resume Payment',
  REVOKE_ACCESS_TOKEN: 'Revoke Access Token',
  GET_USERS_BY_ROLES: 'Get users by roles',
  UUID: 'UUID',
  CREDENTIAL: 'Credential',
  UPDATE: 'Update',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  QUERY_BUILDER: 'Query Builder',
  REGEX: 'Regex',
  RABBITMQ: 'RabbitMQ',
  AWS_SQS: 'AWS SQS',
  ZIP: 'Zip',
  COMPRESS: 'Compress',
  DECOMPRESS: 'Decompress',
  CLEAN_UP_ERRORS: 'Clean up errors',
  CLEAN_HISTORY: 'Clean history',
  LOG: 'Log',
  ROLE: 'Permission: set roles',
  ROLES: 'Roles',
  ENCODER: 'Encoder',
  ENCODE_HTML_SPECIAL_CHARS: 'Encode HTML special chars',
  URL_DECODE: 'URL decode',
  URL_ENCODE: 'URL encode',
  BASE64_ENCODE: 'Base64 encode',
  BASE64_DECODE: 'Base64 decode',
  LIBRARY: 'Library',
  LIBRARIES: 'Libraries',
  ASYNC: 'Asynchronous',
  SYNC: 'Synchronous',
  INLINE: 'Inline',
  STORAGE: 'Storage',
  UPSERT: 'Upsert',
  CLEAN_STORAGE: 'Storage Clean Up',
  CAUGHT: 'Caught',
  FORMATTING: 'Formatting',
  DECIMAL_FORMAT: 'Decimal Format',
  PYTHON: 'Python',
  XSLT: 'XSLT',
  LIBRARIES_SYNC: 'Libraries sync',
  ADD: 'Add (will add additional values to the existing ones)',
  REMOVE: 'Remove (will remove values from the existing ones if they exist)',
  REPLACE: 'Replace (will replace existing values with the new ones)',
  CLEAN_REST_TEMPLATES: 'Clean rest templates',
  TRY: 'Try',
  CATCH: 'Catch',
  FINALLY: 'Finally',
  EXCEPTION: 'Exception',
  CLEAN_GROOVY_SCRIPTS: 'Clean groovy scripts',
  QUERY: 'Query',
  FETCHED: 'Fetched',
  MQTT: 'MQTT',
  MARKDOWN: 'Markdown',
  GRAPHQL: 'GraphQL',
  ALL: 'All',
  EVENT_HANDLER: 'Event Handler',
  TRIGGER_EVENT_HANDLER: 'Trigger Event Handler',
  EVENT_HANDLER_TRIGGERS_SYNC: 'Event Handler triggers sync',
  VARCHAR: 'Varchar',
  BIGINT: 'Bigint',
  OPTIONAL: 'Optional',
  REQUIRED: 'Required',
  ONE_TO_ONE: 'One to one',
  ONE_TO_MANY: 'One to many',
  MANY_TO_ONE: 'Many to one',
  MANY_TO_MANY: 'Many to many',
  ENTITY: 'Entity',
  FIND_BY_ID: 'Find by id',
  HANDLE_EVENTS: 'Handle Events',
  VALIDATION_BODY: 'Validation Body',
  VALIDATION_ID: 'Validation Id',
  JSON: 'JSON',
  CHANGE_ENCODING: 'Change encoding',
  REMOVE_HTML_TAGS: 'Remove HTML tags',
  EXACT: 'Exact',
  BEGINNING: 'Beginning',
  START: 'Start',
  END: 'End',
  EVERYWHERE: 'Everywhere',
  ESC_CHARS: 'ESC/POS converter',
  VALIDATION_PATH: 'Validation Path',
  NONE: 'None',
  BASIC: 'Basic',
  BEARER: 'Bearer',
  REST_BASIC: 'Rest Basic',
  REST_BEARER: 'Rest Bearer',
  FTP: 'FTP',
  specialFtpSftp: 'FTP / SFTP',
  UPLOAD: 'Upload',
  BETWEEN: 'Between',
  SFTP: 'SFTP',
  all: 'All',
  onlyExists: 'Only exists',
  onlyNotExists: 'Only not found',
  onlyMissing: 'Only with missing resources ',
  onlyNotMissing: 'No missing resources',
  onlyConflicts: 'Only with conflicts',
  onlyNotConflicts: 'Not conflicts',
  XML: 'XML',
  JSON_TO_XML: 'JSON to XML',
  XML_TO_JSON: 'XML to JSON',
  INTERRUPT_REQUEST: 'Interrupt Request',
  INTERRUPT_ACKNOWLEDGED: 'Interrupt Acknowledged',
  OCR: 'OCR',
  QR: 'QR',
  OPEN_AI: 'Open AI',
  CHAT_GPT: 'Chat GPT',
  SYSTEM: 'System',
  ASSISTANT: 'Assistant',
  LDAP: 'LDAP',
  SSH: 'SSH',
  SSH_KEY: 'SSH Key',
  BIND: 'Bind',
  MODIFY: 'Modify',
  XLS: 'XLS',
  YAML: 'YAML',
  JSON_TO_YAML: 'JSON to YAML',
  YAML_TO_JSON: 'YAML to JSON',
  XLS_TO_JSON: 'XLS to JSON',
  JSON_TO_XLS: 'JSON to XLS',
  LOW: 'Low',
  MEDIUM: 'Medium',
  QUARTILE: 'Quartile',
  HIGH: 'High',
  I_CALENDAR: 'iCalendar',
  OUTLOOK_CALENDAR: 'Outlook Calendar',
  CLOUD_FRONT: 'CloudFront',
  AUTH: 'Auth',
  MAKE_DIRECTORY: 'Make Directory',
  RENAME: 'Rename',
  GENERATE: 'Generate',
  GET_PAGE_COUNT: 'Get Page Count',
  GET_PAGE_AS_PICTURE: 'Get Page As Picture',
  MERGE: 'Merge',
  GET_TEXT: 'Get Text',
  TIMER: 'Timer',
  CROP: 'Crop',
  SET_VARIABLES: 'Set Variables',
  PROCESS_ASYNC: 'Process Async',
  PROCESS_SYNC: 'Process Sync',
  PROCESS_INLINE: 'Process Inline',
  INSTANCE_SYSTEM_STATISTIC: 'Instance System Statistic',
  CLEAN_EDIT_SESSION: 'Clean Edit Session',
  LOGOUT_USERS: 'Logout Users'
}
