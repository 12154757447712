<template>
  <v-expansion-panels
    v-model="expandedPanels"
    class="stats-panel mx-1"
    multiple
    :class="{ 'stats-panel-rail': rail }"
    variant="accordion"
  >
    <v-expansion-panel>
      <!-- Collapsed View -->
      <v-expansion-panel-title
        class="rail-title d-flex align-center justify-space-between"
        :class="{ 'rail-active': rail }"
      >
        <div v-if="rail" class="collapsed-icons">
          <div class="icon-container pb-1">
            <v-icon color="primary">
              mdi-chip
            </v-icon>
          </div>
          <div class="icon-container rail-spacing">
            <v-icon color="accent">
              mdi-sd
            </v-icon>
          </div>
        </div>

        <div
          v-else-if="expandedPanels.length === 0"
          class="collapsed-stats"
        >
          <div class="stat-item pb-1">
            <v-icon color="primary">
              mdi-chip
            </v-icon>
            <v-progress-linear
              :model-value="averageCpuUsage"
              color="primary"
              height="6"
              style="border-radius: 30px !important; flex: 1; margin: 0 4px"
            />
            <span class="stat-value">{{ formatCpu(averageCpuUsage) }}%</span>
          </div>
          <div class="stat-item">
            <v-icon color="accent">
              mdi-sd
            </v-icon>
            <v-progress-linear
              :model-value="averageMemoryUsage"
              color="accent"
              height="6"
              style="border-radius: 30px !important; flex: 1; margin: 0 4px"
            />
            <span class="stat-value">{{ formatMemory(averageMemoryUsed) }} GB</span>
          </div>
        </div>
        <div v-else class="expanded-header d-flex align-center justify-space-between w-100">
          <span class="header-text">{{ $lang.labels.instances }}</span>
        </div>
      </v-expansion-panel-title>

      <!-- Expanded View -->
      <v-expansion-panel-text class="expansion-panel-padding">
        <div v-if="loading" class="text-center">
          <v-progress-circular indeterminate color="primary" />
        </div>
        <div v-else-if="error" class="text-red text-center">
          Error fetching data
        </div>
        <div v-else>
          <div
            v-for="(instance, index) in statistics"
            :key="index"
            class="instance-stat"
          >
            <h5 class="text-primary">
              <router-link
                :to="{ name: 'logInstanceStats', params: { id: instance.id } }"
                class="text-decoration-none color-primary"
              >
                {{ formatInstanceId(instance.instanceId) }} - {{ $t(`status.${instance.type}`) }}
              </router-link>
            </h5>

            <!-- CPU Usage Section -->
            <div class="stat-title">
              <v-icon color="primary" class="mr-1">
                mdi-chip
              </v-icon>
              CPU
            </div>
            <div class="stat-container">
              <v-tooltip
                location="top"
              >
                <template #activator="{ props }">
                  <v-progress-linear
                    :model-value="calculateProgressCPU(instance.cpu)"
                    color="primary"
                    v-bind="props"
                    height="8"
                    style="border-radius: 30px !important"
                  />
                  <span class="stat-value">{{ formatCpu(instance.cpu.average) }}%</span>
                </template>
                <span>{{ formatCpu(instance.cpu.average) }}% used of 100%</span>
              </v-tooltip>
            </div>
            <div class="stat-minmax pt-1">
              Min: {{ formatCpu(instance.cpu.min) }}% | Max: {{ formatCpu(instance.cpu.max) }}%
            </div>

            <!-- Memory Usage Section -->
            <div class="stat-title d-flex align-center">
              <div class="d-flex align-center">
                <v-icon color="accent" class="mr-1">
                  mdi-sd
                </v-icon>
                Memory
              </div>
              <div class="stat-minmax ml-1">
                ( {{ formatMemory(convertToGB(instance.memory.total)) }} GB )
              </div>
            </div>
            <div class="stat-container">
              <v-tooltip
                location="top"
              >
                <template #activator="{ props }">
                  <v-progress-linear
                    :model-value="calculateProgress(instance.memory)"
                    color="accent"
                    v-bind="props"
                    height="8"
                    style="border-radius: 30px !important"
                  />
                  <span class="stat-value">{{ formatMemory(calculateUsedMemory(instance.memory).average) }} GB</span>
                </template>
                <span>{{ formatMemory(calculateUsedMemory(instance.memory).average) }} GB used of
                  {{ formatMemory(convertToGB(instance.memory.total)) }} GB</span>
              </v-tooltip>
            </div>
            <div class="stat-minmax pt-1">
              Min: {{ formatMemory(calculateUsedMemory(instance.memory).min) }} GB |
              Max: {{ formatMemory(calculateUsedMemory(instance.memory).max) }} GB
            </div>
            <v-divider v-if="index < statistics.length - 1" class="my-2" />
          </div>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import axios from "axios";

export default {
  props: {
    rail: Boolean
  },
  data() {
    return {
      statistics: [],
      loading: true,
      error: false,
      expandedPanels: [],
      intervalId: null
    };
  },
  computed: {
    averageCpuUsage() {
      if (!this.statistics.length) return 0;
      return this.statistics.reduce((sum, instance) => sum + instance.cpu.average, 0) / this.statistics.length;
    },
    averageMemoryUsage() {
      if (!this.statistics.length) return 0;

      const totalMemory = this.statistics.reduce((sum, instance) => sum + this.convertToGB(instance.memory.total), 0) / this.statistics.length;
      const totalAverageMemory = this.statistics.reduce((sum, instance) => sum + this.convertToGB(instance.memory.average), 0) / this.statistics.length;
      const freeMemory = totalMemory - totalAverageMemory;

      return (freeMemory / totalMemory) * 100;
    },
    averageMemoryUsed() {
      if (!this.statistics.length) return 0;

      return this.statistics.reduce((sum, instance) => {
        const used = instance.memory.total - instance.memory.average;
        return sum + this.convertToGB(used);
      }, 0) / this.statistics.length;
    }
  },
  mounted() {
    this.fetchStatistics();
    this.intervalId = setInterval(this.fetchStatistics, 60000);
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    async fetchStatistics() {
      try {
        const response = await axios.get("/api/instance-statistic");
        if (response.data.status === "SUCCESS") {
          this.statistics = response.data.data.items;
        } else {
          this.error = true;
        }
      } catch (err) {
        console.error("Error fetching stats:", err);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    calculateProgressCPU(metric) {
      if (!metric || typeof metric.average !== 'number') return 0;
      return metric.average;
    },
    calculateProgress(metric) {
      if (!metric || !metric.total || !metric.average) return 0;
      const used = metric.average;
      const total = metric.total;
      const free = total - used;
      return (free / total) * 100;
    },
    calculateUsedMemory(memory) {
      if (!memory || !memory.total || !memory.min || !memory.max || !memory.average) {
        return { min: 0, max: 0, average: 0 };
      }

      return {
        min: this.convertToGB(memory.total - memory.max),
        max: this.convertToGB(memory.total - memory.min),
        average: this.convertToGB(memory.total - memory.average)
      };
    },
    convertToGB(bytes) {
      return bytes / (1024 * 1024 * 1024);
    },
    formatCpu(value) {
      return Number(value) % 1 === 0 ? Number(value).toFixed(0) : Number(value).toFixed(1);
    },
    formatMemory(value) {
      return Number(value).toFixed(2);
    },
    formatInstanceId(id) {
      return id ? id.slice(-4) : "????";
    }
  }
};
</script>

<style scoped>
::v-deep(.v-expansion-panel-text__wrapper) {
  padding: 8px 12px 16px !important;
}

::v-deep(.v-expansion-panel-title__icon) {
  transition: transform 0.3s ease-in-out;
  margin-left: 4px !important;
  font-size: 18px;
  color: rgb(var(--v-theme-customNavColor));
  transform: rotate(180deg);
}

::v-deep(.v-expansion-panel-title--active .v-expansion-panel-title__icon) {
  transform: rotate(180deg);
  color: rgb(var(--v-theme-customNavColor));
}

.rail-title.rail-active ::v-deep(.v-expansion-panel-title__icon) {
  display: none !important;
}
.rail-active {
  width: 97% !important;
}
</style>
