import Vuetify from '../../plugins/vuetify'

export default {
  /**
   * Theme and Layout
   */
  globalTheme: (state, theme) => {
    state.globalTheme = theme
  },
  setRTL: (state, isRTL) => {
    Vuetify.framework.rtl = isRTL
    state.isRTL = isRTL
  },
  formValid(state, data) {
    state.formValid = data
  },
  selectedLang(state, data) {
    state.selectedLang = data
  },
  isLoggedIn(state, data) {
    state.isLoggedIn = data
  },
  defaultFrontend(state, data) {
    state.defaultFrontend = data
  },
  savedLocations(state, data) {
    state.savedLocations = data
  },
  userSettings(state, data) {
    state.userSettings = data
  },
  pushSnackbar(state, payload) {
    state.snackbarQueue.push(payload)
  },
  shiftSnackbar(state) {
    state.snackbarQueue.shift()
  },
  /**
   * Process undo/redo
   */
  processPushUndo(state, payload) {
    const newProcessString = JSON.stringify(payload.process)
    const lastProcessUndoValueString = state.processUndoStack?.length
      ? JSON.stringify(state.processUndoStack[state.processUndoStack.length - 1])
      : ''

    if (newProcessString === lastProcessUndoValueString) {
      return
    }

    state.processUndoStack.push(JSON.parse(newProcessString))
  },
  processUndo(state) {
    if (state.processUndoStack.length > 1) {
      const lastState = state.processUndoStack[state.processUndoStack.length - 2]

      state.processRedoStack.push(JSON.parse(JSON.stringify(state.processUndoStack[state.processUndoStack.length - 1])))
      state.processUndoStack.pop()
      state.processCurrent = { ...lastState }
    }
  },
  processRedo(state) {
    if (state.processRedoStack.length > 0) {
      const nextState = state.processRedoStack[state.processRedoStack.length - 1]

      state.processUndoStack.push(JSON.parse(JSON.stringify(nextState)))

      state.processCurrent = { ...nextState }

      state.processRedoStack.pop()
    }
  },
  processClearRedo(state) {
    state.processRedoStack = []
  },
  /* End Process undo/redo */
  saveResourceToRestoreAfterDelete(state, payload) {
    state.resourceToRestoreAfterDelete = payload
  },
  clearResourceFromRestoreAfterDelete(state) {
    state.resourceToRestoreAfterDelete = null
  },
  saveOtherEditingSessions(state, sessions) {
    state.otherEditingSessions = sessions
  },
  clearEditingSessions(state) {
    state.otherEditingSessions = []
  }
}