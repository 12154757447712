module.exports = {
  internalJobs: 'Internal Jobs',
  createCompany: 'Create Company',
  edit: 'Edit',
  activate: 'Activate',
  deactivate: 'Deactivate',
  cancel: 'Cancel',
  create: 'Create',
  update: 'Update',
  submit: 'Submit',
  paid: 'Paid',
  clear: 'Clear',
  selectTime: 'Select time',
  unpaid: 'Unpaid',
  moveItems: 'Move items',
  save: 'Save',
  confirmAndSave: 'Confirm and Save',
  open: 'Open',
  show: 'Show',
  select: 'Select',
  selectDate: 'Select date',
  selectMethod: 'Select method',
  selectTerminal: 'Select terminal',
  reprint: 'Reprint',
  back: 'Back',
  download: 'Download',
  cancellation: 'Cancel',
  print: 'Print',
  delete: 'Delete',
  close: 'Close',
  yes: 'Yes',
  no: 'No',
  addItem: 'Add item',
  none: 'None',
  success: 'Success',
  signatureOk: 'Signature Ok',
  signatureFailed: 'Signature Failed',
  chooseTime: 'Choose time',
  setDefault: 'Set default',
  proceed: 'Proceed',
  generate: 'Generate',
  exportType: 'Select data to be included:',
  backToOrder: 'Back to order',
  noOfPrints: 'No. of prints',
  view: 'View',
  lineOneSearch: 'Enter Line one to Search.',
  clearBasket: 'Clear',
  orderNow: 'Order now',
  refreshBtn: 'Refresh',
  reload: 'Reload',
  selectAll: 'Select All',
  selectNone: 'Select None',
  loading: 'Loading...',
  showInvoice: 'Show invoice',
  startTyping: 'Start typing to Search',
  manageSteps: 'Manage Steps',
  copyCreate: 'Copy/Create',
  saveAll: 'Save All',
  test: 'Test',
  resetPassword: 'Reset Password',
  copy: 'Copy',
  paste: 'Paste',
  pasteBefore: 'Paste before',
  pasteAfter: 'Paste after',
  pasteInstead: 'Paste instead',
  filter: 'Filter',
  duplicate: 'Duplicate',
  execute: 'Execute',
  check: 'Check',
  restore: 'Restore',
  add: 'Add',
  openProcess: 'Open',
  createProcess: 'Create process',
  triggerCreateProcess: 'You haven\'t added any process. Do you want to create one',
  export: 'Export',
  import: 'Import',
  validate: 'Validate',
  All: 'All',
  clickToImport: 'Click To Import',
  clickToUpload: 'Click To Upload',
  copyUrl: 'Copy Url',
  addRule: 'Add Rule',
  openRule: 'Open',
  addField: 'Add Field',
  openTemplate: 'Open',
  generateApiKey: 'Generate Api Key',
  copyApiKey: 'Copy Api Key',
  uploadModule: 'Upload',
  downloadModule: 'Download',
  install: 'Install',
  remove: 'Remove',
  addAction: 'Add Action',
  deleteAction: 'Delete Action',
  openPlugin: 'Open',
  searchStep: 'Search Step Name',
  reset: 'Reset',
  downloadSwagger: 'Swagger',
  stats: 'Stats',
  addReview: 'Add Review',
  showReviews: 'Show Reviews',
  addCc: 'Add CC',
  addBcc: 'Add BCC',
  hideCc: 'Hide CC',
  hideBcc: 'Hide BCC',
  pdf: 'PDF',
  HTML: 'HTML',
  openCredentialLink: 'Open',
  searchCredentialType: 'Search Credential Type',
  areYouSureYouWantToDelete: 'Are you sure you want to delete',
  forceDelete: 'Force Delete',
  details: 'Details',
  restoreCreate: 'Restore/Create',
  start: 'Start',
  next: 'Next',
  run: 'Run',
  upload: 'Upload',
  createFromMemory: 'Memory/Create',
  createFromModal: 'Modal/Create',
  link: 'Link',
  copyLink: 'Copy Link',
  areYouSureSessions: 'Do you want to delete all sessions of the ',
  logout: 'Logout all sessions',
  apply: 'Apply',
  changeStatus: 'Change Status',
  goToEntity: 'Go to Entity',
  settings: 'Settings',
  startOnboarding: 'Start Onboarding'
}
