module.exports = {
  credentialStatusUpdate: 'Failed to update credential status',
  credentialUpdate: 'Failed to update credential',
  credentialCreate: 'Failed to create credential',
  credentialInsecureMessage: 'The credentials are stored without certificate. This is not secure.',
  cronStatusUpdate: 'Failed to update cron status',
  cronUpdate: 'Failed to update cron',
  cronCreate: 'Failed to create cron',
  restStatusUpdate: 'Failed to update rest status',
  restUpdate: 'Failed to update rest',
  restCreate: 'Failed to create rest',
  dateWrong: 'Date From cannot be after date to',
  processStatusUpdate: 'Failed to update process status',
  processUpdate: 'Failed to update process',
  processCreate: 'Failed to create process',
  processRequired: 'Process is required',
  missingUsername: 'Please enter your username.',
  missingPassword: 'Please enter your password.',
  somethingWentWrong: 'Something went wrong! :(',
  maybeLinkWasExpired: 'Maybe your link expired',
  userStatusUpdate: 'Failed to update user status',
  userUpdate: 'Failed to update user',
  userCreate: 'Failed to create user',
  roleUpdate: 'Failed to update role',
  roleCreate: 'Failed to create role',
  test: 'Test Unsuccessful',
  templateUpdate: 'Failed to update template',
  templateCreate: 'Failed to create template',
  translationUpdate: 'Failed to update translation',
  translationCreate: 'Failed to create translation',
  translationDelete: 'Failed to delete translation',
  resendPassword: 'Failed to send password reset instructions',
  settingStatusUpdate: 'Failed to update setting status',
  settingUpdate: 'Failed to update setting',
  settingCreate: 'Failed to create setting',
  staticResourceUpdate: 'Failed to update static resource',
  staticResourceCreate: 'Failed to create static resource',
  staticResourceStatusUpdate: 'Failed to update static resource status',
  staticResourceUpload: 'Failed to upload static resource',
  staticResourceDownoad: 'Failed to download static resource',
  zeroSteps: 'Please add at least one step in order to be able to save a process',
  noPaste: 'No step item in memory',
  wrongPaste: 'Item in memory is of the wrong type',
  tokenMissing: 'Token Missing',
  passwordNotSame: 'Passwords do not match',
  formatEmail: 'Not valid Email format',
  required: 'Required',
  cronExecute: 'Cron execute error',
  check: 'Check Error',
  checkIsExists: 'Combination of Method and Url already exists with trigger ID:',
  duplicatedKeys: 'Duplicated key detected',
  moduleUpdate: 'Failed to update module',
  moduleCreate: 'Failed to create module',
  readOnly: 'You are accessing this resource in read only mode',
  editRoleCreate: 'Resource should have at least one Edit Role assigned',
  moduleExport: 'Failed to export module',
  moduleImport: 'Failed to import module',
  moduleValidate: 'Failed to validate module',
  moduleTooBig: 'Your import is currently taking longer than usual due to the size of the data. The process is still ongoing and is expected to complete successfully. Please check back later to confirm its status.',
  noFeSettings: 'No active frontend settings found',
  multipleFeSettings: 'Multiple frontend settings found, please fix configuration',
  ttlToBig: 'TTL is more than 10 years into the future',
  validationUpdate: 'Failed to update validation rule',
  validationCreate: 'Failed to create validation rule',
  noLogs: 'No step logs found for Event ID',
  variableJavaWrong: 'Name is not by Variable Naming Convention',
  valueNotAllowed: 'Value Not Allowed',
  apiKeyGeneration: 'Error generating ApiKey',
  moduleUpload: 'Failed to upload module',
  moduleDownload: 'Failed to download module',
  moduleInstall: 'Failed to install module',
  pluginStatusUpdate: 'Failed to update plugin status',
  pluginUpdate: 'Failed to update plugin',
  pluginCreate: 'Failed to create plugin',
  pluginDelete: 'Failed to delete plugin',
  pluginImport: 'Failed to import plugin',
  nameInUse: 'Process name already in use',
  noCache: 'No cache for this step log',
  duplicatedVars: 'Duplicated variable detected',
  execute: 'Script execution Unsuccessful',
  multipleCredentials: 'Multiple matching credentials found!',
  noEvents: 'No events available',
  triggerRestUrlValidation: 'The following field(s) are not defined in the path variable rule',
  triggerRestPathVarValidation: 'The following field(s) are defined in the path variable rule, but not in the URL',
  restPathValidation: 'The path must start with "/" ',
  mustBeTwoLong: 'Must be two characters',
  errorSwagger: 'Error downloading Swagger',
  multipleSettings: 'Multiple matching settings found!',
  wrongSearchParam: 'Incorrect value',
  slackMessage: 'Message too long',
  pdfGenerate: 'PDF generation error',
  htmlGenerate: 'HTML generation error',
  mustLessExecutions: 'Simultaneous executions per instance must be less than or equal to overall simultaneous executions',
  nothingToCopy: 'Nothing to copy',
  missingEmail: 'Please enter your email.',
  missingCaptcha: 'Please enter the captcha.',
  messagingStatusUpdate: 'Failed to update messaging status',
  messagingUpdate: 'Failed to update messaging',
  messagingCreate: 'Failed to create messaging',
  selectServiceFirst: 'Please select a service first',
  libraryStatusUpdate: 'Failed to update library status',
  libraryUpdate: 'Failed to update library',
  libraryCreate: 'Failed to create library',
  libraryUpload: 'Failed to upload library',
  passwordLength: 'Password must be at least 6 characters long',
  passwordsMatch: 'Old and new passwords must be different',
  passwordsDontMatch: 'Passwords do not match',
  storageUpdate: 'Failed to update storage',
  storageCreate: 'Failed to create storage',
  credentialVariableName: 'Credentials using variable names are assigned at runtime.',
  eventHandlerStatusUpdate: 'Failed to update event handler status',
  eventHandlerUpdate: 'Failed to update event handler',
  eventHandlerCreate: 'Failed to create event handler',
  listenProcessSame: 'Listen process cannot be the same as the process being listened to',
  entityStatusUpdate: 'Failed to update entity status',
  entityUpdate: 'Failed to update entity',
  entityCreate: 'Failed to create entity',
  entityValidation: 'Failed to validate entity',
  entityGenerate: 'Failed to generate entity',
  entityNameCantStartWithNumber: 'Entity name cannot start with a number',
  entityFieldNamesCantStartWithNumber: 'Field names cannot start with a number',
  entityWithThisNameExists: 'Entity with this name already exists',
  onePrimaryKey: 'Entity must have one primary key',
  historyFetch: 'Failed to fetch history',
  fieldCantHaveIdName: 'Field Name cannot be "_id" as this is a reserved name for the system',
  nameTooShortOrLong: 'Name must be between 2 and 250 characters long',
  nameTooShortOrLong64: 'Name must be between 2 and 64 characters long',
  restBasic: 'Both username and password are required for basic authentication',
  restBearer: 'Bearer token is required for bearer authentication',
  noPermission: 'You do not have permission for this or connected resources',
  cannotUseVariableFormat: 'Cannot use variable format',
  urlStartsWithSystem: 'URL cannot start with /system',
  credentialCorruptedMessage: 'Required fields are missing in the credential. Please enter the necessary values.',
  unauthorized: 'You do not have permission to access this resource.',
  noShadowObject: 'Shadow objects not found for the selected entity.',
  dataEmpty: 'Empty data returned',
  noDataAvailable: 'No data available',
  notFound: 'Resource not Found',
  invalidJson: 'Invalid JSON value in field: ',
  moduleDescription: 'Description field cannot be empty or contain only spaces. Please enter a valid description.',
  moduleName: 'Name field cannot be empty or contain only spaces. Please enter a valid name.',
  entityMustContainNonPrimaryField: 'A primary key field cannot be the only field defined; please add at least one more field.',
  entityCantBeDeleted: 'Entity can not be deleted, as it is referenced by others',
  listOfUsedEntities: 'The entity you are trying to delete is used in the following entities:',
  invalidJsonFields: 'Invalid JSON in fields:',
  fillRequiredFields: 'Fill in required fields!',
  fieldEditedSuccessfully: 'Field edited successfully',
  fieldCreatedSuccessfully: 'Field created successfully',
  errorParsingJsonFields: 'Error parsing JSON field',
  max30Chars: 'Max 30 characters',
  onlyLettersNumbersUnderscore: 'Only letters, numbers and underscore are allowed',
  reservedPage: 'The name page is reserved for internal usage, please use a different name',
  reservedSize: 'The name size is reserved for internal usage, please use a different name',
  invalidJsonInFields: 'Invalid JSON in fields',
  interruptEventsError: 'Failed to interrupt events',
  wrongAuthCombination: 'Wrong authentication combination',
  tooShortOrLong64: 'Must be between 1 and 64',
  logsTimeLessThanMax: 'Logs TTL and Error Logs TTL must be higher than Max Execution Time',
  max10years: 'TTL is more than 10 years into the future',
  between1Msecand7day: 'Duration must be between 1 millisecond and 7 days',
  nameTooShort: 'Name must be at least 2 characters long',
  notAllRequiredInputVariablesSet: 'Not all required input variables are set',
  noSettingsFound: 'No settings found',
  noQueryParametersInRestUUrl: 'No query parameters allowed in REST URL. Please use corresponding validations.',
  urlValidation: 'The path must either be a variable or contain only letters,numbers,hyphens and underscores',
  mustBeGreaterThanZero: 'Must be greater than zero',
  invalidPasswordSpaces: 'Password must not be empty ',
  invalidTimestamp: 'Invalid timestamp format! Example: 2021-01-01 00:00:00',
  booleanCannotBePrimaryKey: 'Boolean fields cannot be set to primary key',
  noSpaces: 'Content field cannot be blank.',
  greaterThanZeroFUllNumber: 'The value must be a whole number greater than zero',
  cannotDeleteDueToRelation: 'Cannot delete due to relation with another entity',
  noRestoreResource: 'No resource found to restore',
  fieldDeletedSuccessfully: 'Field deleted successfully',
  numericOnly: 'Only numeric values are allowed',
  otpLength: 'OTP must be 6 characters long'
}
