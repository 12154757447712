<template>
  <div v-if="otherEditingSessions.length" class="editing-users-header d-flex align-center ml-auto mr-n2">
    <v-tooltip
      v-for="session, index in otherEditingSessions"
      :key="session.email"
      location="top"
      class="text-center"
    >
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          color="white"
          rounded
          variant="text"
          size="24"
          :class="{'ml-2': index !== 0, 'editing-user-icon': true}"
        >
          {{ session.name.charAt(0) }}
        </v-btn>
      </template>
      {{ session.name }} <br> ({{ session.email }})
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EditingUsersHeader',
  computed: {
    ...mapState('app', ['otherEditingSessions'])
  }
}
</script>

<style lang="scss">
.editing-users-header {
  height: max(var(--v-input-control-height, 56px), 1.5rem + var(--v-field-input-padding-top) + var(--v-field-input-padding-bottom));
  padding: 0.5rem;
  border: 1px solid rgb(var(--v-theme-accent));
  border-radius: 12px;
}

.editing-user-icon {
  background-color: rgb(var(--v-theme-primary));
}
</style>
